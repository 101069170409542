import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '../css/MainApp.css';
import MusicCard from './MusicCard';
import NavBar from './NavBar';

let userId = localStorage.getItem("userId");

const UserMusic = () => {

    const [musicList, setMusicList] = useState([]);
    const [search, setSearch] = useState("");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {

        try {
            if(Number(userId) === 123450) {
                Swal.fire("Personal Music not available on admin account. All uploaded songs will be available publicly.");
            } else {
            const response = await axios.get(`https://imusic.jazcodeit.ca/backend/AppDriver.php?userId=${userId}`);

            const data = response.data;
            const formattedMusicList = data.map(item => ({
                rowsReturned: item.rowsReturned,
                musicId: item.musicId,
                musicTitle: item.title,
                musicArtist: item.artist,
                musicAlbum: item.album,
                musicDescription: item.description,
                musicYear: item.yearReleased,
                songSrc: item.songSrc,
                albumCover: item.albumSrc,
            }));

            // store all of musicId of user to localstorage
            for(let i = 0; i < data.length; i++) {
                let localStorageId = "user_musicId" + i; 
                localStorage.setItem(localStorageId, data[i].musicId);
                localStorage.setItem('userMusicTotal', data.length);
            }

            // Set musicList as an array with a multiple musicData object
            setMusicList(formattedMusicList);

            }

        } catch (err) {

            if(err) {
                Swal.fire({
                    title: "Too many Users!", 
                    text: "iMusic is temporarily unavailable to use for some users due to server overload. You will be placed in a queue, Close the App and try again later.", 
                    footer: "jazcodeit.ca",
                    imageUrl: "https://c.tenor.com/47qpxBq_Tw0AAAAC/tenor.gif",
                    imageWidth: 250,
                    imageHeight: 250,
                    imageAlt: "500: Internal Server Error",
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    backdrop: "#000",
                })
            }

            console.log("Error Fetching User Data: " + err);
        }
    
    }

    const handleSearch = event => {
        setSearch(event.target.value);
    } 

    let userDir = "https://imusic.jazcodeit.ca/backend/userUploads/"+userId+"/"
    
    return (
        <>
        <div className="UserMusic">

            <h1 className='text-center m-1'>iMusic</h1>
            <p className='text-light m-0 text-center'>Created by Jaz<br/></p>

            <nav className="searchBar">
                <div>
                    <input onChange={handleSearch} value={search} className="form-control" type="search" placeholder="Search music by title..." aria-label="Search" max="12" />
                </div>
                
                <p>Personal Songs Available: {musicList[0] ? musicList[0].rowsReturned : "0"}</p>
            </nav>

        {musicList.length > 0 ? (
                    <div>
                        {musicList
                            .filter(music => music.musicTitle.toLowerCase().includes(search.toLowerCase()))
                            .map((music, index) => (
                                <MusicCard
                                    key={index}
                                    musicId={music.musicId}
                                    albumCover={userDir+music.albumCover}
                                    musicTitle={music.musicTitle}
                                    musicYear={music.musicYear}
                                    musicArtist={music.musicArtist}
                                    musicAlbum={music.musicAlbum}
                                    musicDescription={music.musicDescription}
                                    deleteEnabled={true}
                                />
                            ))}
                    </div>
                ) : (
                    <p className="text-center">Loading please wait...</p>
                )}

                {/* Display message if no results found */}
                {musicList.length > 0 && musicList.filter(music => music.musicTitle.toLowerCase().includes(search.toLowerCase())).length === 0 && (
                    <p className="text-center">No matching songs found.</p>
                )}

        </div>
        <NavBar />
        </>
  );
}

export default UserMusic;
