import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '../css/Login.css';



const Login = () => {

  // check if user already active
  let isUserActive = localStorage.getItem('userId');
  if(isUserActive){
    window.open('./App', '_self');
  }

  // this will remove the "" on the json data extracted
  const sterilizeJsonlData = data => {
    return data.substring(1, data.length-1);
  }

  const [showPassword, setShowPassword] = useState(false);

  const handleChangesPasswordSettings = () => {
    setShowPassword(!showPassword);
  }

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const handleFormSubmit = async event => {
    event.preventDefault();

    try {

      const formToPost = new FormData();
      formToPost.append("username", formData.username);
      formToPost.append("password", formData.password);
      formToPost.append("login", true);

      let response = await axios.post("https://imusic.jazcodeit.ca/backend/AppDriver.php", formToPost);

      console.log(response.data);
      // check if response inlcudes userid
      if(JSON.stringify(response.data.userId) === "null"){

        Swal.fire({title: "Login Failed!", text: "Invalid username or password. Make sure you have a valid account to login.", icon: "error", confirmButtonColor: "#000"});
      
      } else {
        // store userid in localstorage
        localStorage.setItem('userId', sterilizeJsonlData(JSON.stringify(response.data.userId)));
        localStorage.setItem('accessCode', sterilizeJsonlData(JSON.stringify(response.data.accessCode)));


        Swal.fire({title: "Login Success!", text: "Login Successful! Please read disclaimer before using the app.", icon: "success", confirmButtonColor: "#000", confirmButtonText: "Read Disclaimer"})
        .then(() => {
          Swal.fire({title: "Disclaimer", text: "JazCodeit.ca may use copyrighted material which has not always been specifically authorized by the copyright owner. JazCodeit.ca believes this constitutes for a 'fair use' of any such copyrighted material as provided in section 29 of the Copyright Act of Canada. If You wish to use copyrighted material from the Service for your own purposes that go beyond fair use, You must obtain permission from the copyright owner. UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR OUR MOBILE APPLICATION OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE AND OUR MOBILE APPLICATION. YOUR USE OF THE SITE AND OUR MOBILE APPLICATION AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE AND OUR MOBILE APPLICATION IS SOLELY AT YOUR OWN RISK.", icon: "info", confirmButtonColor: "#000", confirmButtonText: "Accept"})
          .then(() => { window.open('./App', '_self') });
        });


      }

    } catch (err) {

      if(err) {
        Swal.fire({
            title: "Too many Users!", 
            text: "iMusic is temporarily unavailable to use for some users due to server overload. You will be placed in a queue, Close the App and try again later.", 
            footer: "jazcodeit.ca",
            imageUrl: "https://c.tenor.com/47qpxBq_Tw0AAAAC/tenor.gif",
            imageWidth: 250,
            imageHeight: 250,
            imageAlt: "500: Internal Server Error",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            backdrop: "#000",
        })
      }

      console.log("Axios Error! - " + err);
    }

  }

  const handleChange = event => {
    const {name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  }


  return (
    <div className="Login">

        <h1>iMusic</h1>
        <p className='text-light'>Created by Jaz</p>
        
        <form method='POST' onSubmit={handleFormSubmit}>

          <h2 className='text-danger'>Login</h2>

          <label for='username' className='form-label'>Username: </label>
          <input
            value={formData.username}
            onChange={handleChange}
            type='text' id='username'
            name='username'
            className='form-control'
            placeholder='Enter Username'
            required
          />

          <label for='password' className='form-label'>Password: </label>
          <input 
            value={formData.password}
            onChange={handleChange}
            type={ showPassword ? "text" : "password" }
            id='password'
            name='password'
            className='form-control'
            placeholder='Enter Password'
            required 
          />

          <div className='loginForm-buttons'>

            <div className='passwordSettings text-center' onClick={handleChangesPasswordSettings}>
              <p>
                {showPassword ? <i class="bi bi-eye-slash"></i> : <i class="bi bi-eye"></i>} {showPassword ? "Hide Password" : "Show Password"}
              </p>
            </div>

            <button type='submit' name='login' className='btn btn-primary'>Login</button>
            <p className='mt-2'>No account? <span className='text-danger' onClick={() => {window.open('./Register', '_self')}}>Register Now</span></p>
          </div>

        </form>
    </div>
  );
}

export default Login;
