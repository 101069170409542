import React from 'react';
import '../css/NavBar.css';

const NavBar = () => {
    return (
        <div className='NavBar'>
            
            <div onClick={() => {window.open('./UploadMusic', '_self');}} className='NavBar-item'>
                <i className="bi bi-cloud-arrow-up-fill"></i>
            </div>

            <div onClick={() => {window.open('./App', '_self');}} className='NavBar-item'>
                <i className="bi bi-house-fill"></i>
            </div>

            <div onClick={() => {window.open('./UserMusic', '_self');}} className='NavBar-item'>
                <i className="bi bi-music-note-list"></i>
            </div>

        </div>
    );
}

export default NavBar;