import React, { useEffect, useState } from 'react';
import '../css/MusicPlayer.css';
import axios from 'axios';
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
});

let userId = localStorage.getItem("userId");

const MusicPlayer = () => {

  // set refferer on first call and it will be used when user choose to stay in the musicplayer 
  if(!document.referrer.includes("MusicPlayer")) {
    document.referrer.includes("App") ? localStorage.setItem("refferer", "App") : localStorage.setItem("refferer", "User");
  }
  let refferer = localStorage.getItem("refferer"); // get the refferer from localstorage and store in as a local field
  
  const [musicPlayer, setMusicPlayer] = useState(null);
  const [musicControl, setMusicControl] = useState({
    isPlaying: false,
    isOnRepeat: false,
    musicDurationMinutes: 0,
    musicRemainingDurationSeconds: 0,
    musicDurationTotalSeconds: 0,
    currentMusicTime: 0,
  });

  const [audioObj, setAudioObj] = useState(null);
  console.log({...musicControl});


  useEffect(() => {
    fetchMusicData();
  }, []);


  useEffect(() => {

    if (musicPlayer && musicPlayer.songSrc) {
      const audio = new Audio(refferer === "App" ? musicPlayer.songSrc : "https://imusic.jazcodeit.ca/backend/userUploads/"+userId+"/"+ musicPlayer.songSrc);
      audio.preload = "auto";
      audio.autoplay = true;
      setAudioObj(audio);
    }

  }, [musicPlayer, refferer]);

  useEffect(() => {
    let progressBarInterval;
  
    // Effect to set up audio event listeners and manage progress bar
    if (audioObj) {

      // When audio metadata (including duration) is loaded
      audioObj.addEventListener("loadedmetadata", () => {
        // Update music duration in seconds and set progress bar max value
        setMusicControl(prevState => ({
          ...prevState,
          musicDurationTotalSeconds: Math.round(audioObj.duration),
        }));
        document.getElementById('progressBar').max = Math.round(audioObj.duration);
      });
  
      // Event listener for updating progress bar during playback
      audioObj.addEventListener("timeupdate", () => {
        if (!audioObj.ended) {
          // Update progress bar value with current playback time
          document.getElementById('progressBar').value = audioObj.currentTime;
        }
      });
  
      // Event listener for when audio playback ends
      audioObj.addEventListener("ended", () => {
        // Clear the interval for progress bar update
        clearInterval(progressBarInterval);
        // Update state to reflect playback state (not playing)
        setMusicControl(prevState => ({ ...prevState, isPlaying: false }));
        // shuffle when current music ended
        handleMusicControls("shuffle");
      });
  
      // Start interval to update progress bar every second
      progressBarInterval = setInterval(() => {
        if (!audioObj.ended) {
          // Update progress bar value with current playback time
          document.getElementById('progressBar').value = audioObj.currentTime;
        } else {
          // If audio playback ends, clear the interval
          clearInterval(progressBarInterval);
        }
      }, 1000);
    }
  
    // Clean up function to clear interval on component unmount or audio change
    return () => {
      clearInterval(progressBarInterval);
    };
  }, [audioObj]);

  const fetchMusicData = async () => {

    try {

      
      let musicId = localStorage.getItem('currentMusicSelected');

      const res = await axios.get(`https://imusic.jazcodeit.ca/backend/AppDriver.php?musicId=${musicId}`);
      
      setMusicPlayer(res.data);
    } catch (err) {

      if(err) {
        Swal.fire({
            title: "Too many Users!", 
            text: "iMusic is temporarily unavailable to use for some users due to server overload. You will be placed in a queue, Close the App and try again later.", 
            footer: "jazcodeit.ca",
            imageUrl: "https://c.tenor.com/47qpxBq_Tw0AAAAC/tenor.gif",
            imageWidth: 250,
            imageHeight: 250,
            imageAlt: "500: Internal Server Error",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            backdrop: "#000",
        })
    }

      console.log("Axios: " + err);
    }

  }
  
  const handleMusicControls = event => {

    // calculate duration of the music
    let audioTotalDurationMinutes;
    let audioRemainingDurationSeconds;
    let audioDurationTotalSeconds;

    if(audioObj) {

      audioTotalDurationMinutes = Math.round(audioObj.duration / 60);

      audioRemainingDurationSeconds = Math.round(audioObj.duration % 60);
      // add leading zero if number is less than 10
      audioRemainingDurationSeconds = audioRemainingDurationSeconds < 10 ? "0" + audioRemainingDurationSeconds : audioRemainingDurationSeconds;

      audioDurationTotalSeconds = audioObj.duration;

      console.log("Music Duration: " + audioTotalDurationMinutes + " minutes and " + audioRemainingDurationSeconds + " seconds. Duration Total in Seconds " + audioDurationTotalSeconds)
    
    }


    setMusicControl(prevState => {

      switch(event) {

        case "play":
          return {...prevState, 
            isPlaying: true, 
            musicDurationMinutes: audioTotalDurationMinutes,
            musicRemainingDurationSeconds: audioRemainingDurationSeconds,
            musicDurationTotalSeconds: audioDurationTotalSeconds,
          };

        case "pause":
          return {...prevState, isPlaying: false};
        case "repeat":

          return {...prevState, isOnRepeat: true};
        case "no-repeat":
          return {...prevState, isOnRepeat: false};

        default:
          return prevState;

      }

    });


    // Music Controls
    switch(event) {

      case "play":
        if(audioObj && musicControl && !musicControl.isPlaying) {

          try {

            // check if mediaSession is available
            if("mediaSession" in navigator) {

              if(navigator.mediaSession.metadata !== null) {

                navigator.mediaSession.metadata = new MediaMetadata({
                  title: musicPlayer.musicTitle + " | iMusic - Jaz Code it✔️",
                  artist: musicPlayer.musicArtist,
                  album: musicPlayer.musicAlbum,
                  artwork: [{ src: musicPlayer.albumSrc ? musicPlayer.albumSrc : musicPlayer.albumSrc.replace(".png", ".webp") }],
                });
  
              } else {
  
                // keep trying to add metadata of the current music while metadata is still null
                while (navigator.mediaSession.metadata == null) {
                  navigator.mediaSession.metadata = new MediaMetadata({
                    title: musicPlayer.musicTitle + " | iMusic - Jaz Code it✔️",
                    artist: musicPlayer.musicArtist,
                    album: musicPlayer.musicAlbum,
                    artwork: [{ src: musicPlayer.albumSrc ? musicPlayer.albumSrc : musicPlayer.albumSrc.replace(".png", ".webp") }],
                  });
                }
  
              }

            } else {
              // if mediaSession not available at the moment
              Swal.fire("Session Not available");
            }
            
            // show on load
            Toast.fire({
              icon: "success",
              title: "Loading please wait...",
              timer: 1000,
            }).then(() => {

              audioObj.play()
              .then(() => {
                // show on success
                Toast.fire({
                  icon: "success",
                  title: "Playing " + musicPlayer.musicTitle + " by " + musicPlayer.musicArtist + "...",
                  timer: 1000,
                });

            });

            
  
            });
  
          } catch (error) {

            Swal.fire({
              title: "Error Cannot Play Music", 
              text: error + " Please try again.", 
              icon: "error", 
              confirmButtonColor: "red",
              allowOutsideClick: false,
              allowEscapeKey: false,
              backdrop: "#000",
            }).then(() => {window.open('./MusicPlayer', '_self');});

          }
          
        }
        break;

      case "pause":
        if(audioObj && musicControl && musicControl.isPlaying) {
          audioObj.pause();
        }
        break;

      case "repeat":
        if(audioObj) {
          audioObj.loop = true;
        }
        Toast.fire({
          icon: "info",
          title: "Repeat Enabled. This song will repeat."
        });
        break;

      case "no-repeat":
        if(audioObj) {
          audioObj.loop = false;
        }
        Toast.fire({
          icon: "info",
          title: "Repeat Disabled. This song will NOT repeat."
        });
        break;

      case "shuffle":
        if(audioObj) {

          // check if mediaSession is available
          if("mediaSession" in navigator) {

            if(navigator.mediaSession.metadata !== null) {

              navigator.mediaSession.metadata = new MediaMetadata({
                title: "Thanks for using iMusic",
                artist: "Jaz Code it✔️",
                album: "jazcodeit.ca",
                artwork: [{ src: "logo.webp" }],
              });

            } else {

              // keep trying to add metadata of the current music while metadata is still null
              while (navigator.mediaSession.metadata == null) {
                navigator.mediaSession.metadata = new MediaMetadata({
                  title: "Thanks for using iMusic",
                  artist: "Jaz Code it✔️",
                  album: "jazcodeit.ca",
                  artwork: [{ src: "logo.webp" }],
                });
              }

            }

          } else {
            // if mediaSession not available at the moment
            Swal.fire("Session Not available");
          }
          
          let varMusicTotal;
          let varMusicId;
          // check if music list going to be shuffle is public or user playlist
          if(refferer === "App") {
            varMusicTotal = "publicMusicTotal";
            varMusicId = "public_musicId";
          } else {
            varMusicTotal = "userMusicTotal";
            varMusicId = "user_musicId";
          }

          let currentMusic = localStorage.getItem('currentMusicSelected');
          // get user music total from localstorage
          let userMusicTotal = localStorage.getItem(varMusicTotal);
          // generate random number with the max of user music total
          let randomNum = Math.floor(Math.random() * userMusicTotal);
          // get musicId stored in localstorage with the random number
          let randomMusic = localStorage.getItem(varMusicId + randomNum);
          
          // check if current music is the same with the random music selected
          while(currentMusic === randomMusic) {
            // generate new random number
            randomNum = Math.floor(Math.random() * userMusicTotal);
            randomMusic = localStorage.getItem(varMusicId + randomNum);
          }
          
          // set new current music then releod the page
          localStorage.setItem('currentMusicSelected', randomMusic);
          
          Toast.fire({
            icon: "success",
            title: "Playing next random song...",
            timer: 600,
          }).then(() => {
            window.open("./MusicPlayer?musicId="+randomMusic, "_self");
          });
          
        } else {
          Toast.fire({
            icon: "error",
            title: "Cannot get next song."
          });
        }
        break;

      default:
          break;
          
    }
     
  }

  const handleProgressBarDrag = (event) => {
    const newPosition = event.target.value;
    
    if(audioObj) {
      audioObj.currentTime = newPosition;
      console.log("Change audio position to: " + newPosition);
    }
  }

  return (
    <div className="MusicPlayer">

      <button onClick={() => {window.open('./App', '_self');}} className='btn btn-primary goBackBtn'>Go Back</button>

        <div className='card mb-3'>
            <div className='col'>
                
                <div className='col'>
                    <img src={musicPlayer ? refferer === "App" ? musicPlayer.albumSrc : "https://imusic.jazcodeit.ca/backend/userUploads/"+userId+"/"+ musicPlayer.albumSrc : "loader.gif"} className='albumCoverPlayer' alt='Loading album cover...' title='Music Album Cover' />
                </div>

                <div className='row'>
                    <div className='card-body'>
                        <h5 className='card-title'>{musicPlayer ? musicPlayer.musicTitle : "Loading data..."}</h5>

                        <b className='card-text'>{musicPlayer ? musicPlayer.musicArtist + " | " : ""}{musicPlayer && musicPlayer.musicAlbum ? musicPlayer.musicAlbum + " | " : ""}{musicPlayer ? musicPlayer.yearReleased : ""}</b>

                        <p className='card-text'>{musicPlayer ? musicPlayer.musicDescription : ""}</p>
                    </div>
                </div>

                {/* <progress id='progressBar' className='loader' value="0" min="0" max="100"></progress> */}
                <input onChange={handleProgressBarDrag} type='range' id='progressBar' className='loader' value="0" min="0" max="100" />
  
                <p className='musicDuration'>
                  {musicControl && musicControl.musicDurationMinutes ? musicControl.musicDurationMinutes + ":" : ""}
                  {musicControl && musicControl.musicRemainingDurationSeconds ? musicControl.musicRemainingDurationSeconds : ""}
                </p>

                <div className='musicPlayerControls'>
                  {musicControl && musicControl.isOnRepeat ? <i onClick={() => handleMusicControls("no-repeat")} class="bi bi-repeat-1"></i> : <i onClick={() => handleMusicControls("repeat")} class="bi bi-repeat"></i>}

                  {musicControl && musicControl.isPlaying ? <i onClick={() => handleMusicControls("pause")} class="bi bi-pause-circle-fill"></i> : <i onClick={() => handleMusicControls("play")} class="bi bi-play-circle-fill"></i>}
                  
                  <i onClick={() => handleMusicControls("shuffle")} class="bi bi-shuffle"></i>
                </div>

            </div>
       </div>

    </div>
  );
}

export default MusicPlayer;
