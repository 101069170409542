import React from 'react';
import './css/Homepage.css';
import Swal from 'sweetalert2';

const Homepage = () => {

    let d = new Date();
    let installEvent = null;

    // listen on install prompt event then store the event to the field for later use
    window.addEventListener("beforeinstallprompt", e => {
        // dont show the event prompt to user yet
        e.preventDefault();

        // store install event prompt in field
        installEvent = e;
    });

    // check what os user is using
    const checkOS = () => {

        if(navigator) {

            let device = navigator.userAgent;

            if(device.includes("iPhone") || device.includes("iPad")) {
                console.log("You're using " + device);
                return "IOS";
            } else if(device.includes("Android")) {
                console.log("You're using " + device);
                return "Android";
            } else {
                console.log("You're using " + device);
                return "Windows/Mac";
            }
    
        } else {
            Swal.fire({title: "Navigator not Available!", icon: "error", confirmButtonColor: "#000"});
        }

    } 

    // show installation guide to user
    const showInstallationGuide = device => {
        
        if(device === checkOS()) {
            switch(device) {
                case "IOS":
                    Swal.fire({
                        title: "IOS Installation", 
                        text: "Follow the guide on how to install the app on ios devices.", 
                        imageUrl: "tutorial.gif",
                        imageWidth: 350,
                        imageHeight: 450,
                        confirmButtonColor: "#953343",
                        confirmButtonText: "Done",
                        color: "#FFF",
                        background: "#000"
                    });
                    break;
                case "Android":

                    // prompt user to install the app
                    if(installEvent) {
                        installEvent.prompt();
                    } else {
                        Swal.fire({
                            title: "Installer not Ready",
                            text: "Initializing installer... Keep clicking 'Try again' button to check if the installer is ready, Do not reload the webpage or it will reset your progress",
                            confirmButtonText: "Try again",
                            confirmButtonColor: "#000",
                            imageUrl: "https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExY21xOGphZDJvcTY1YmFzOWxiNTlocTNua25hYnc5N3FxN2huOWd6aSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/xT5LMRTs1ny71BGYH6/giphy.webp",
                            imageWidth: 350,
                            imageHeight: 350,
                            imageAlt: "iMusic"
                          }).then(() => {
                            // recursion
                            showInstallationGuide("Android");
                          });
                    }
                    
                    break;
                case "Windows/Mac":
                    Swal.fire({title: "Not Supported", text: "You can only install this on Android or IOS Device. Open this webpage in your device and click download button.", icon: "error", confirmButtonColor: "#000"});
                    break;
                default:
                    break;
            }
        } else {
            if(checkOS() === "Windows/Mac") {
                Swal.fire({title: "Cannot Download the App", text: "To download and install the app you must open this webpage on your device.", icon: "error", confirmButtonColor: "#000"});
            } else {
                Swal.fire({title: "Incorrect Operating System", text: "Select the correct operating system to install the App. Your Operating System is " + checkOS() + " not " + device, icon: "error", confirmButtonColor: "#000"});
            }
        }

    }

    return(
        <div className='Homepage'>


                <img className='logo' src='logo.webp' alt='Tutorial' />
                
                <div className='intro'>
                    <h1>iMusic</h1>
                    <p>Upload and create your own personal music library and Stream music for Free</p>
                </div>
                
                <div className='download-section'>

                    <p>Available in IOS and Android Devices</p>
                    <div>
                        <img onClick={() => {showInstallationGuide("Android")}} className='android-dl-btn' src='ad_dl.png' alt='Android Download Button' title='iMusic' />
                        <img onClick={() => {showInstallationGuide("IOS")}} className='ios-dl-btn' src='ios_dl.svg' alt='IOS Download Button' title="iMusic" />
                    </div>
                </div>
                
                <footer onClick={() => {window.open('https://jazcodeit.ca')}}>&copy; {d.getFullYear()} Jaz Code it</footer>

        </div>
    );
}

export default Homepage;
