import React from 'react';
import '../css/Settings.css';

let userId = localStorage.getItem("userId");
let accessCode = localStorage.getItem('accessCode');

const Settings = () => {


    return(
        <div className='Settings'>

            <button onClick={() => {window.open('./App', '_self')}} className='btn btn-primary'><i className="bi bi-music-note-beamed"></i> Go back <i className="bi bi-music-note-beamed"></i></button>
            
            {accessCode.includes("LinkedIn") || accessCode.includes("Tester") ? "" : <button onClick={() => {window.open('https://paypal.me/jaztech')}} className='btn btn-success'><i className="bi bi-wallet"></i> Donate</button> }

            <button onClick={() => {window.open('mailto:contact@jazcodeit.ca?subject=Request/Suggestion%20-%20iMusic%20App%20'+userId, '_self')}} className='btn btn-secondary'><i className="bi bi-postage-heart-fill"></i> Request/Suggestion</button>

            <button onClick={() => {window.open('mailto:contact@jazcodeit.ca?subject=Report%20Issue%20-%20iMusic%20App%20'+userId, '_self')}} className='btn btn-secondary'><i className="bi bi-flag-fill"></i> Report Issue</button>

            <button onClick={() => {window.open('./Login?logout=1', '_self')}} className='btn btn-danger'><i className="bi bi-box-arrow-right"></i> Logout</button>

            <div>
                {
                    accessCode.includes("LinkedIn") ? 
                <p>
                    <b>iMusic Version 1.0 Created By Jaz</b><br/>
                    The Purpose of this App is for me Enhance my Knowledge and Skills in Full Stack Development<br/><br/>

                    jazcodeit.ca
                </p> :
                <p>
                    <b>iMusic Version 1.0 Created By Jaz</b><br/>
                    The Purpose of this App is for me not to pay any of these Music Streaming Platforms<br/><br/>

                    It started when I realized that <br/>
                    "Why am I paying for these Music Streaming Platforms when I am a freaking software/web developer, I can make my own Music App"<br/>
                    and that's when iMusic has been born.<br/><br/>

                    jazcodeit.ca
                </p>
                }
            </div>
        </div>
    );
}

export default Settings;
