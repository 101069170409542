import React, { useState } from 'react';
import '../css/UploadMusic.css';
import NavBar from './NavBar';
import axios from 'axios';
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
});

let userId = localStorage.getItem("userId");

const UploadMusic = () => {
    
    const [formSubmit, setFormSubmit] = useState(true);
    const [formData, setFormData] = useState({
        mp3File: null,
        albumFile: null,
        musicTitle: "",
        musicArtist: "",
        musicAlbum: "",
        musicDescription: "",
        yearReleased: null
    });


    const handleFileUpload = async e => {
        e.preventDefault(); // remove reload after form submit

        console.log(formData.albumFile);
        // check if user didnt select anything for the files
        if(formData.mp3File === null) {
            Swal.fire({
                title: "Missing mp3 file",
                text: "Cannot upload form with missing mp3 file!",
                icon: "error",
                confirmButtonColor: "#000"
            });
        } else if(formData.albumFile === null){
            Swal.fire({
                title: "Missing image file",
                text: "Cannot upload form with missing image file!",
                icon: "error",
                confirmButtonColor: "#000"
            });
        } else {

            const formToSubmit = new FormData();
            formToSubmit.append("userId", userId);
            formToSubmit.append("mp3File", formData.mp3File);
            formToSubmit.append("albumFile", formData.albumFile);
            formToSubmit.append("musicTitle", formData.musicTitle);
            formToSubmit.append("musicArtist", formData.musicArtist);
            formToSubmit.append("musicAlbum", formData.musicAlbum);
            formToSubmit.append("musicDescription", formData.musicDescription);
            formToSubmit.append("yearReleased", formData.yearReleased);
            formToSubmit.append("uploadMusic", true);
    
            try {
    
                Toast.fire({
                    icon: "info",
                    title: "Uploading music please wait... Do not leave the page until the upload is finish",
                    timer: 30000,
                });
    
                // disable submit button
                setFormSubmit(false);
    
                // issue in cors on file upload so we will change the api url base on where we are running the app
                let api;
                if(window.location.href.includes("localhost") || window.location.href.includes("127.0.0.1")) {
                    api = "http://localhost/music_app/backend/AppDriver.php";
                } else {
                    api = "https://imusic.jazcodeit.ca/backend/AppDriver.php";
                }
                
                const response  = await axios.post(api, formToSubmit, {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                });
    
                // check if error exist in the api response
                if(response.data.error) {
    
                    Swal.fire({
                        title: "Error Uploading",
                        text: "Error uploading because " + response.data.error,
                        icon: "error",
                        confirmButtonColor: "#000",
                        confirmButtonText: "Try again"
                    }).then(() => {
                        window.open("./UploadMusic", "_self");
                    });
    
                } else {
    
                    // check if file is uploaded
                    if(response.data.isFileUploaded) {
    
                        Swal.fire({
                            title: "Upload Success",
                            text: "Music has been added into your personal music library.",
                            icon: "success",
                            confirmButtonColor: "#000"
                        }).then(() => {
                            window.open("./UserMusic", "_self");
                        });
    
                    }
    
                }
    
            } catch (err) {
    
                Swal.fire({
                    title: "Error",
                    text: "Error " + err,
                    icon: "error",
                    confirmButtonColor: "#000"
                });
    
            }
        }
    }

    const handleChange = event => {
        const {name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }

    const handleFileChange = event => {
        const {name, files } = event.target;
        setFormData((prevFormData) => ({ 
            ...prevFormData, 
            [name]: files[0]
        }));
    }

    return (
    <>
        <div className='UploadMusic'>

            <h1>Upload Music</h1>
            <p className='text-light text-center'>Uploaded music will be stored in your personal music library.</p>

            <form className='form-group' method='POST' encType='multipart/form-data' onSubmit={handleFileUpload}>
                
                <div className='mp3File'>
                    <label for='mp3File' className='btn btn-primary'>Load mp3</label>
                    <span id='mp3FileChosen'> {formData.mp3File !== null ? "mp3 file selected" : ""}</span>
                    <input 
                        id='mp3File' 
                        name='mp3File'
                        type='file' 
                        accept='.mp3' 
                        className='form-control-file' 
                        onChange={handleFileChange}
                        hidden  
                    />
                </div>
                
                <div className='albumFile'>
                    <label for='albumFile' className='btn btn-primary' type='button'>Load Album Cover</label>
                    <span id='albumFileChosen'> {formData.albumFile !== null ? "Img file Selected" : ""}</span>
                    <input 
                        id='albumFile' 
                        name='albumFile' 
                        type='file' 
                        className='form-control-file' 
                        onChange={handleFileChange} 
                        hidden 
                    />
                </div>

                <div>
                    <label for='musicTitle'>Title: </label>
                    <input 
                        id='musicTitle' 
                        name='musicTitle' 
                        type='text' 
                        placeholder='Music Title'
                        className='form-control' 
                        required 
                        value={formData.musicTitle}
                        onChange={handleChange}
                    />
                </div>

                <div>
                    <label for='musicArtist'>Artist: </label>
                    <input 
                        id='musicArtist' 
                        name='musicArtist' 
                        type='text' 
                        placeholder='Music Artist'
                        className='form-control' 
                        required 
                        value={formData.musicArtist}
                        onChange={handleChange}
                    />
                </div>

                <div>
                    <label for='musicAlbum'>Album Name: </label>
                    <input 
                        id='musicAlbum' 
                        name='musicAlbum' 
                        type='text' 
                        placeholder='Music Album Name'
                        className='form-control' 
                        required 
                        value={formData.musicAlbum}
                        onChange={handleChange}
                    />
                </div>

                <div>
                    <label for='musicDescription'>Description: </label>
                    <input 
                        id='musicDescription' 
                        name='musicDescription' 
                        type='text' 
                        placeholder='Music Description'
                        className='form-control' 
                        required 
                        value={formData.musicDescription}
                        onChange={handleChange}
                    />
                </div>

                <div>
                    <label for='yearReleased'>Year: </label>
                    <input 
                        id='yearReleased' 
                        name='yearReleased' 
                        type='number' 
                        placeholder='Year Released'
                        className='form-control' 
                        required 
                        value={formData.yearReleased}
                        onChange={handleChange}
                    />
                </div>

                {formSubmit === true ? <button type='submit' name='uploadMusic' className='btn btn-primary uploadMusic'>Upload Music</button> : "Upload in progress..."}
            </form>
        </div>
        <NavBar />
    </>
    );
}

export default UploadMusic;