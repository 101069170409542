import React from 'react';
import '../css/MusicCard.css';
import axios from 'axios';
import Swal from 'sweetalert2';

let userId = localStorage.getItem("userId");

// Template for music card on the main app
const MusicCard = ({musicId, albumCover, musicTitle, musicYear, musicArtist, musicAlbum, musicDescription, deleteEnabled}) => {

    const openMusic = async method => {
        if(deleteEnabled && method === "delete") {
            try {
                const formData = new FormData();
                formData.append("userId", userId);
                formData.append("musicId", musicId);
                formData.append("deleteSong", true);

                const response = await axios.post('https://imusic.jazcodeit.ca/backend/AppDriver.php', formData);

                if(response.data.isDeleted) {
                    Swal.fire({
                        title: "Song deleted", 
                        text: "The song is deleted from your personal music library.", 
                        icon: "success", 
                        confirmButtonColor: "#000"
                    }).then(() => {
                        window.location.reload();
                    });
                } else {
                    Swal.fire({
                        title: "Error Deleting Song", 
                        text: response.data.error, 
                        icon: "error", 
                        confirmButtonColor: "#000"
                    }).then(() => {
                        window.location.reload();
                    });
                }

            } catch (err) {
                Swal.fire({title: "Error Deleting Song", text: "Cannot delete song because " + err, icon: "error", confirmButtonColor: "#000"})
            }
        } else {
            let url = "MusicPlayer?musicId=" + musicId;
            localStorage.setItem("currentMusicSelected", musicId);
            window.open(url, '_self');
        }
    }
    
    return(
        <div className='MusicCard'>
            <div className='card'>
                
                <div className=''>
                    <img className='albumCover' src={albumCover ? albumCover.replace(".png", ".webp") : "loader.gif"} alt='Album Cover Not Available' title='Music Album Cover' />
                </div>

                <div className='card-body'>
                    <h5 className='card-title'>{musicTitle}</h5>
                    <b className='card-text'>{musicArtist} | </b>
                    <b className='card-text'>{musicAlbum ? musicAlbum : musicYear}</b>
                    <p className='card-text'>{musicDescription}</p>
                </div>

                <div className='musicCardSettings'>
                    <button onClick={openMusic} className='btn btn-success'>Listen</button>
                    {deleteEnabled === true ? <button onClick={() => {openMusic("delete")}} className='btn btn-danger'>Delete</button> : ""}
                </div>

            </div>
        </div>
    );
}

export default MusicCard;