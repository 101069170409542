import React from 'react';
import ReactDOM from 'react-dom/client';
import './components/css/index.css';
import App from './App';

// Imported Libraries
import Swal from 'sweetalert2';
import 'bootstrap-icons/font/bootstrap-icons.css';

// Limit Access to non authenticated users
let isUserActive = localStorage.getItem('userId');
// check if user id is set and check for route that is only for authenticated users
if(!isUserActive && (
  (window.location.href).includes("/App") || 
  (window.location.href).includes("/MusicPlayer") || 
  (window.location.href).includes("/DeleteMusic") || 
  (window.location.href).includes("/YtMusic")
)) {
    Swal.fire({
        title: "Access Denied!", 
        text: "Please login to use the App!", 
        icon: "error", 
        confirmButtonColor: "red",
        allowOutsideClick: false,
        allowEscapeKey: false,
        backdrop: "#000",
    })
    .then(() => {window.open('./Login', '_self');});
} 


// check if user is offline
window.addEventListener("offline", (e) => {
  
  if(!window.location.href.includes("./") ) {
    Swal.fire({
      title: "No Internet Connection!", 
      text: "Please connect to the internet and try again.", 
      icon: "error", 
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      backdrop: "#000",
    });
  }
  
});

if(window.location.href.includes("localhost") || window.location.href.includes("127.0.0.1")) {
  console.log("Development Mode");
} else {

  if(window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone) {
    console.log("standalone activated");
  } else {
    window.location.href.includes("/?download=true") ? console.log("homepage") : window.open('./?download=true', '_self');
  }

}

// User Logout
if(isUserActive && window.location.href.includes("logout=1")) {
  localStorage.clear();
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
