import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './components/css/App.css';

import Homepage from './components/Homepage';
import Login from './components/Homepage/Login';
import Register from './components/Homepage/Register';
import MainApp from './components/MainApp/MainApp';
import MusicPlayer from './components/MainApp/MusicPlayer';
import UploadMusic from './components/MainApp/UploadMusic';
import UserMusic from './components/MainApp/UserMusic';
import Settings from './components/MainApp/Settings';

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Homepage />}></Route>
          <Route path='/Login' element={<Login />}></Route>
          <Route path='/Register' element={<Register />}></Route>
          <Route path='/App' element={<MainApp />}></Route>
          <Route path='/MusicPlayer' element={<MusicPlayer />}></Route>
          <Route path='/UploadMusic' element={<UploadMusic />}></Route>
          <Route path='/UserMusic' element={<UserMusic />}></Route>
          <Route path='/Settings' element={<Settings />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
