import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '../css/Login.css';

const Register = () => {

  // check if user already active
  let isUserActive = localStorage.getItem('userId');
  if(isUserActive){
    window.open('./App', '_self');
  }
  
  const [formData, setFormData] = useState({
    email:"",
    username: "",
    password: "",
    accessCode: "",
  });

  const handleFormSubmit = async event => {
    event.preventDefault();

    try {

      let isValidCode;
      // check access code for user restricions
      switch(formData.accessCode.toLowerCase()){
        case "linkedin":
          isValidCode = true;
          break;
        case "f&f":
          isValidCode = true;
          break;
        case "tester": 
          isValidCode = true;
          break;
        default:
          isValidCode = false;
          break;
      }

      if(isValidCode) {

        const formToPost = new FormData();
        formToPost.append("email", formData.email);
        formToPost.append("username", formData.username);
        formToPost.append("password", formData.password);
        formToPost.append("accessCode", formData.accessCode);
        formToPost.append("register", true);
  
        let response = await axios.post("https://imusic.jazcodeit.ca/backend/AppDriver.php", formToPost);
        
        // check if response user exist true
        if(JSON.stringify(response.data.error) === "true"){
          
          if(JSON.stringify(response.data.status).includes("Limit Reached")) {
            Swal.fire({title: "Registration Failed!", text: "The access code you’re using has exceeded its maximum number of uses.", icon: "error", confirmButtonColor: "#000"});
          } else {
            Swal.fire({title: "Registration Failed!", text: "Email or Username already exist! If you already have an account please login.", icon: "error", confirmButtonColor: "#000"});
          }
          
        } else {
  
          Swal.fire({title: "Registration Success!", text: "Registration Successful! You can now login to your account.", icon: "success", confirmButtonColor: "#000"})
          .then(() => {window.open('./Login', '_self')});
  
        }
        
      } else {
        Swal.fire({title: "Invalid Access Code!", text: "Enter valid access code to register. To obtain valid access code you must contact the developer.", icon: "error", confirmButtonColor: "#000"});
      }

    } catch (err) {

      if(err) {
        Swal.fire({
            title: "Too many Users!", 
            text: "iMusic is temporarily unavailable to use for some users due to server overload. You will be placed in a queue, Close the App and try again later.", 
            footer: "jazcodeit.ca",
            imageUrl: "https://c.tenor.com/47qpxBq_Tw0AAAAC/tenor.gif",
            imageWidth: 250,
            imageHeight: 250,
            imageAlt: "500: Internal Server Error",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            backdrop: "#000",
        })
      }

      console.log("Axios Error! - " + err);
    }

  }

  const handleChange = event => {
    const {name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  }


  return (
    <div className="Login">

        <h1>iMusic</h1>
        <p className='text-light'>Created by Jaz</p>
        
        <form method='POST' onSubmit={handleFormSubmit}>

          <h2 className='text-danger'>Register</h2>

        <label for='email' className='form-label'>Email: </label>
            <input
              value={formData.email}
              onChange={handleChange}
              type='email' id='email'
              name='email'
              className='form-control'
              placeholder='Enter Email'
              required
            />

          <label for='username' className='form-label'>Username: </label>
            <input
              value={formData.username}
              onChange={handleChange}
              type='text' id='username'
              name='username'
              className='form-control'
              placeholder='Enter Username'
              required
            />

          <label for='password' className='form-label'>Password: </label>
            <input 
              value={formData.password}
              onChange={handleChange}
              type='text'
              id='password'
              name='password'
              className='form-control'
              placeholder='Enter Password'
              required 
            />

          <label for='accessCode' className='form-label'>Access Code: </label>
            <input
              value={formData.accessCode}
              onChange={handleChange}
              type='text' id='accessCode'
              name='accessCode'
              className='form-control'
              placeholder='Enter Acess Code'
              required
            />

          <div className='loginForm-buttons'>

            <button type='submit' name='register' className='btn btn-primary'>Register</button>
            <p className='mt-2'>Already have an account? <span className='text-danger' onClick={() => {window.open('./Login', '_self')}}>Login Now</span></p>
          </div>

        </form>
    </div>
  );
}

export default Register;
